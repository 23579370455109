export function formspreeUrls() {
  const formspreeUrlsObj = {
    // PRODUCTION - NEW
    formspreeSubmitYourProjectURL: "https://formspree.io/f/mvonrvpj",
    formspreePartialSubmitYourProjectURL: "https://formspree.io/f/mwkjbweg",
    formspreeContactMeURL: "https://formspree.io/f/mrgvkogz",

    // PRODUCTION - OLD
    // formspreeSubmitYourProjectURL: "https://formspree.io/f/mknagpww",
    // formspreePartialSubmitYourProjectURL: "https://formspree.io/f/mpzewyvv",
    // formspreeContactMeURL: "https://formspree.io/f/mgebelqv",

    // DEV ONLY
    // formspreeSubmitYourProjectURL: "https://formspree.io/f/xgebedgp",
    // formspreePartialSubmitYourProjectURL: "https://formspree.io/f/mayzndoy",
    // formspreeContactMeURL: "https://formspree.io/f/xgebeqkl",
  };
  return formspreeUrlsObj;
}
