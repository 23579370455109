import React, { useState, useEffect, useMemo } from "react";

import { useForm, Controller } from "react-hook-form";

import { Heading, Select, Center, VStack, Box } from "@chakra-ui/react";
import { Input, Textarea } from "@chakra-ui/react";

export default function Component3(props) {
  React.useEffect(() => {
    // console.log(props);
  }, [props]);

  // react-hook-form
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const watchAllFields = watch(); // when pass nothing as argument, you are watching everything
  // Callback version of watch.  It's your responsibility to unsubscribe when done.
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // console.log(value, name, type);
      // console.log(value);
      props.onReturnComp({
        howConnectToNetwork: value.HowConnectToNetwork,
        wirelessCapabilities: value.WirelessCapabilities,
        supportRequirements: value.SupportRequirements,
      });
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (data) => console.log(data);
  // console.log(errors);

  // WHEN CLICKING THROUGH WIZARD, FORCE LAYOUT
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <form onSubmit={handleSubmit(onSubmit)}> */}
      <Box w={{ sm: "90%", md: "90%" }}>
        <Heading as="h5" size="sm" mb={"10px"} mt={"10px"}>
          How does your project connect to T-Mobile's Network?
        </Heading>
        <Textarea
          variant="filled"
          value={props?.props?.howConnectToNetwork}
          placeholder="Example..."
          {...register("HowConnectToNetwork", { required: true, maxLength: 300 })}
        />

        <Heading as="h5" size="sm" mb={"10px"} mt={"10px"}>
          What specific wireless capabilities does it leverage?
        </Heading>
        <Textarea
          variant="filled"
          value={props?.props?.wirelessCapabilities}
          placeholder="Example..."
          {...register("WirelessCapabilities", { required: true, maxLength: 300 })}
        />

        <Heading as="h5" size="sm" mb={"10px"} mt={"10px"}>
          The 5G Hub offers space, access to 5G and support from network engineers. What, if any, support does your project require?
        </Heading>
        <Textarea
          variant="filled"
          value={props?.props?.supportRequirements}
          placeholder="Example..."
          {...register("SupportRequirements", { required: true, maxLength: 300 })}
        />
      </Box>
      {/* </form> */}
    </>
  );
}
