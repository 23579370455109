import React, { useState, useEffect, useMemo } from "react";

import { Image, Container, Heading, Select, Center, VStack, Box, Flex, Button, Spacer } from "@chakra-ui/react";

export default function EndCapSubmitYourProject(props) {
  const handleMainMenuBtn = () => {
    // console.log("handle Main Menu Button");
    props.onReturnFromForm("mainMenu");
  };

  return (
    <>
      <Container centerContent={true} maxW={"100vw"} h={"100vh"} backgroundColor={"#CF2B72"} px={{ sm: "5vmin", md: "15vmin" }}>
        <Flex direction={"column"} alignItems={"center"} h={"100%"}>
          <Heading mt={"8vmin"} fontSize={{ sm: "2.5vmin", md: "3.5vmin" }} color={"gray.100"} textAlign={"center"} variant="innovate">
            INNOVATE WITH US
          </Heading>
          <Spacer />

          {props?.props === "completed" && (
            <Center>
              <Flex direction={"column"}>
                <Heading fontSize={{ sm: "5vmin", md: "5vmin" }} color={"white"} textAlign={"center"}>
                  Thanks for sharing your innovation project with us!
                </Heading>
                <Heading mt={"5vmin"} fontSize={{ sm: "5vmin", md: "5vmin" }} color={"white"} textAlign={"center"}>
                  The team will review and get back to you within 5 business days.
                </Heading>
              </Flex>
            </Center>
          )}

          {props?.props === "partial" && (
            <Center>
              <Flex direction={"column"}>
                <Heading fontSize={{ sm: "5vmin", md: "5vmin" }} color={"white"} textAlign={"center"}>
                  Thanks for your interest!
                </Heading>
                <Heading mt={"5vmin"} fontSize={{ sm: "5vmin", md: "5vmin" }} color={"white"} textAlign={"center"}>
                  We'll email you a link to this form, and you can complete it later.
                </Heading>
              </Flex>
            </Center>
          )}

          <Spacer />

          <Center w={"40vmin"} h={"20vmin"} backgroundColor={"white"} onClick={() => handleMainMenuBtn()} style={{ cursor: "pointer" }}>
            <Heading fontSize={"4vmin"} color={"#CF2B72"} textAlign={"center"} variant="innovate">
              Main Menu
            </Heading>
          </Center>
          <Spacer />
        </Flex>
      </Container>
    </>
  );
}
