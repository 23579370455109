import React, { useState, useEffect, useMemo } from "react";

import { useForm, Controller } from "react-hook-form";

import { Heading, Select, Center, VStack, Box } from "@chakra-ui/react";
import { Input, Textarea } from "@chakra-ui/react";

export default function Component2(props) {
  React.useEffect(() => {
    // console.log(props);
  }, [props]);

  // react-hook-form
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const watchAllFields = watch(); // when pass nothing as argument, you are watching everything
  // Callback version of watch.  It's your responsibility to unsubscribe when done.
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // console.log(value, name, type);
      // console.log(value);
      props.onReturnComp({
        projectName: value.ProjectName,
        projectSummary: value.ProjectSummary,
        projectGoal: value.ProjectGoal,
        startDate: value.StartDate,
        completionDate: value.CompletionDate,
      });
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (data) => console.log(data);
  // console.log(errors);

  // WHEN CLICKING THROUGH WIZARD, FORCE LAYOUT
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <form onSubmit={handleSubmit(onSubmit)}> */}
      <Box w={{ sm: "90%", md: "90%" }}>
        <Heading as="h5" size="sm" mb={"10px"}>
          What is the name of your project?
        </Heading>
        <Input
          variant="filled"
          value={props?.props?.projectName}
          placeholder="Your project name"
          size="md"
          {...register("ProjectName", { required: true, maxLength: 80 })}
        />

        <Heading as="h5" size="sm" mb={"10px"} mt={"10px"}>
          Project Summary ('Elevator Pitch')
        </Heading>
        <Textarea
          variant="filled"
          value={props?.props?.projectSummary}
          placeholder="Example..."
          {...register("ProjectSummary", { required: true, maxLength: 300 })}
        />

        <Heading as="h5" size="sm" mb={"10px"} mt={"10px"}>
          What is the project goal or objective and how will success be measured?
        </Heading>
        <Textarea
          variant="filled"
          value={props?.props?.projectGoal}
          placeholder="Example..."
          {...register("ProjectGoal", { required: true, maxLength: 300 })}
        />

        <Heading as="h5" size="sm" mb={"10px"} mt={"10px"}>
          Estimated Start Date
        </Heading>
        <Input
          variant="filled"
          value={props?.props?.startDate}
          placeholder="Select Date and Time"
          size="md"
          type="date"
          {...register("StartDate", { required: true, maxLength: 80 })}
        />

        <Heading as="h5" size="sm" mb={"10px"} mt={"10px"}>
          Estimated Completion Date
        </Heading>
        <Input
          variant="filled"
          value={props?.props?.completionDate}
          placeholder="Select Date and Time"
          size="md"
          type="date"
          {...register("CompletionDate", { required: true, maxLength: 80 })}
        />
      </Box>
      {/* </form> */}
    </>
  );
}
