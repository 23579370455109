import React, { useState, useEffect, useMemo, useLayoutEffect } from "react";

import { useForm, Controller } from "react-hook-form";

import { Heading, Select, Center, VStack, Box, Flex, Button, Spacer, Container } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

import { AiOutlineHome } from "react-icons/ai";

import axios from "axios";

import EndCapLearnMore from "./EndCapLearnMore";

import { formspreeUrls } from "../helpers/formspreeUrls";

export default function LearnMore(props) {
  // FORMDATA STATE VARIABLE
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    department: "",
  });

  const [endCapLearnMore, setEndCapLearnMore] = useState(false);

  React.useEffect(() => {
    // console.log(props);
  }, [props]);

  // react-hook-form
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const watchAllFields = watch(); // when pass nothing as argument, you are watching everything
  // Callback version of watch.  It's your responsibility to unsubscribe when done.
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // fields have changed- update formData
      var formDataTMP = JSON.parse(JSON.stringify(formData));
      if (value.Name) {
        formDataTMP.name = value.Name;
      }
      if (value.Email) {
        formDataTMP.email = value.Email;
      }
      if (value.Phone) {
        formDataTMP.phone = value.Phone;
      }
      if (value.Department) {
        formDataTMP.department = value.Department;
      }
      setFormData(formDataTMP);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (data) => console.log(data);
  // console.log(errors);

  // ██    ██  █████  ██      ██ ██████   █████  ████████ ███████
  // ██    ██ ██   ██ ██      ██ ██   ██ ██   ██    ██    ██
  // ██    ██ ███████ ██      ██ ██   ██ ███████    ██    █████
  //  ██  ██  ██   ██ ██      ██ ██   ██ ██   ██    ██    ██
  //   ████   ██   ██ ███████ ██ ██████  ██   ██    ██    ███████

  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    if (formData.name !== "" && formData.email !== "" && formData.phone !== "" && formData.department !== "") {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [formData]);

  // ███████  ██████  ██████  ███    ███ ███████ ██████  ██████  ███████ ███████
  // ██      ██    ██ ██   ██ ████  ████ ██      ██   ██ ██   ██ ██      ██
  // █████   ██    ██ ██████  ██ ████ ██ ███████ ██████  ██████  █████   █████
  // ██      ██    ██ ██   ██ ██  ██  ██      ██ ██      ██   ██ ██      ██
  // ██       ██████  ██   ██ ██      ██ ███████ ██      ██   ██ ███████ ███████

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      // form.reset();
      // console.log("got an OK repsonse from server");
      // time to display the end cap screen
      setEndCapLearnMore(true);
    }
  };

  const handleOnSubmit = () => {
    // console.log("submit to Formspree");

    const form = new FormData();
    for (var key in formData) {
      form.append(key, formData[key]);
    }

    setServerState({ submitting: true });
    axios({
      method: "post",
      url: formspreeUrls().formspreeContactMeURL,
      data: form,
    })
      .then((r) => {
        handleServerResponse(true, "Thanks!", form);
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  // ██████  ███████ ███████ ██████   ██████  ███    ██ ███████ ██ ██    ██ ███████
  // ██   ██ ██      ██      ██   ██ ██    ██ ████   ██ ██      ██ ██    ██ ██
  // ██████  █████   ███████ ██████  ██    ██ ██ ██  ██ ███████ ██ ██    ██ █████
  // ██   ██ ██           ██ ██      ██    ██ ██  ██ ██      ██ ██  ██  ██  ██
  // ██   ██ ███████ ███████ ██       ██████  ██   ████ ███████ ██   ████   ███████

  const [contentHeight, setContentHeight] = useState("auto");
  const [contentPadTop, setContentPadTop] = useState("auto");

  useLayoutEffect(() => {
    var width = window.innerWidth;
    var height = window.innerHeight;
    calculateContentHeight(width, height);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      // console.log(window.innerHeight, window.innerWidth);
      var width = window.innerWidth;
      var height = window.innerHeight;
      calculateContentHeight(width, height);
    });
  }, [window.innerHeight, window.innerWidth]);

  const calculateContentHeight = (width, height) => {
    var conditionsArray = [
      // ipad air portrait
      width === 820 && height === 1180,
      // ipad air landscape
      width === 1180 && height === 820,
      // ipad regular / mini portrait
      width === 768 && height === 1024,
      // ipad regular / mini landscape
      width === 1024 && height === 768,
      // ipad pro portrait
      width === 1024 && height === 1366,
      // ipad pro landscape
      width === 1366 && height === 1024,
      // desktop
      width > 1366,
    ];

    if (conditionsArray.includes(true)) {
      setContentHeight("100%");
      setContentPadTop("0");
    } else {
      setContentHeight("auto");
      setContentPadTop("5vh");
    }
  };

  // ███    ███ ██ ███████  ██████
  // ████  ████ ██ ██      ██
  // ██ ████ ██ ██ ███████ ██
  // ██  ██  ██ ██      ██ ██
  // ██      ██ ██ ███████  ██████

  // REFRESH / RELOAD
  const refreshPage = () => {
    // console.log("refreshing the page");
    window.location.reload();
  };

  // MISC
  const homeBtnStyle = { color: "#DDDDDD" };

  // ██       █████  ██    ██  ██████  ██    ██ ████████
  // ██      ██   ██  ██  ██  ██    ██ ██    ██    ██
  // ██      ███████   ████   ██    ██ ██    ██    ██
  // ██      ██   ██    ██    ██    ██ ██    ██    ██
  // ███████ ██   ██    ██     ██████   ██████     ██

  return (
    <>
      {!endCapLearnMore && (
        <>
          <Container p={0} position={"absolute"} maxW={"100vw"} h={"100vh"} backgroundColor={"#CF2B72"}>
            <Box position={"fixed"} top={"2"} right={"2"} style={{ cursor: "pointer" }} zIndex={"999"}>
              <AiOutlineHome style={homeBtnStyle} size={"6vmin"} onClick={() => refreshPage()} />
            </Box>
            <Flex direction={{ sm: "column", md: "row" }}>
              {/* this is the HEADER */}
              <Center
                backgroundColor={"#CF2B72"}
                w={{ sm: "100vw", md: "30vw" }}
                h={{ sm: "18vh", md: "100vh" }}
                position={"sticky"}
                top={0}
                left={0}
                zIndex={"998"}
              >
                <Flex direction={"column"} h={{ sm: "18vh", md: "100%" }} w={{ sm: "100vw", md: "30vw" }} pt={{ sm: "2%", md: "6%" }}>
                  <Center h={{ sm: "25%", md: "10%" }}>
                    <Heading fontSize={{ sm: "2.5vmin", md: "3.5vmin" }} color={"gray.100"} textAlign={"center"} variant="innovate">
                      {/* INNOVATE WITH US */}
                    </Heading>
                  </Center>

                  <Center h={{ sm: "75%", md: "90%" }} px={{ sm: "16vmin", md: "4vmin" }} mt={{ sm: "0", md: "-20" }}>
                    <Heading fontSize={{ sm: "4vmin", md: "4vmin" }} color={"white"} textAlign={"center"}>
                      Fill out your contact details to receive more information.
                    </Heading>
                  </Center>
                </Flex>
              </Center>
              {/* this is the CONTENT */}
              <Box backgroundColor={"white"} w={{ sm: "100%", md: "70vw" }} position={"relative"} top={"15%"} h={{ sm: "85vh", md: "100vh" }} zIndex={"1"}>
                <Flex
                  direction={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  // bg="gray.200"
                  position={"relative"}
                  pt={contentPadTop}
                  height={contentHeight}
                  pb={"2"}
                >
                  <Center w={{ sm: "90%", md: "90%" }} bg="white" pb={"6vmin"}>
                    {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                    <Box px={{ sm: "20px", md: "40px" }}>
                      <Heading as="h5" size="sm" mb={"10px"}>
                        Name
                      </Heading>
                      <Input
                        variant="filled"
                        value={formData?.name}
                        placeholder="Your full name"
                        size="md"
                        w={{ sm: "80vw", md: "50vw" }}
                        {...register("Name", { required: true, maxLength: 80 })}
                      />

                      <Heading as="h5" size="sm" mb={"10px"} mt={"10px"}>
                        Email
                      </Heading>
                      <Input
                        variant="filled"
                        value={formData?.email}
                        placeholder="you@example.com"
                        size="md"
                        {...register("Email", { required: true, maxLength: 80 })}
                      />

                      <Heading as="h5" size="sm" mb={"10px"} mt={"10px"}>
                        Mobile Phone
                      </Heading>
                      <Input
                        variant="filled"
                        value={formData?.phone}
                        placeholder="000-000-0000"
                        size="md"
                        {...register("Phone", { required: true, maxLength: 15 })}
                      />

                      <Heading as="h5" size="sm" mb={"10px"} mt={"10px"}>
                        Department
                      </Heading>
                      <Input
                        variant="filled"
                        value={formData?.company}
                        placeholder="Example Company"
                        size="md"
                        {...register("Department", { required: true, maxLength: 80 })}
                      />
                    </Box>
                    {/* </form> */}
                  </Center>
                  {/* these are the "buttons" */}
                  <Flex alignItems={"center"} w={"80%"} ml={{ sm: "-4vmin", md: "-9vmin" }}>
                    <Spacer />
                    {formIsValid && (
                      <Center w={"30vmin"} h={"12vmin"} backgroundColor={"#CF2B72"} onClick={() => handleOnSubmit()} style={{ cursor: "pointer" }}>
                        {!serverState.submitting && (
                          <Heading fontSize={"3.5vmin"} color={"white"} textAlign={"center"} variant="innovate">
                            Submit
                          </Heading>
                        )}
                        {serverState.submitting && <Spinner />}
                      </Center>
                    )}
                    {!formIsValid && (
                      <Center w={"30vmin"} h={"12vmin"} backgroundColor={"#AAAAAA"} style={{ cursor: "pointer" }}>
                        <Heading fontSize={"3.5vmin"} color={"white"} textAlign={"center"} variant="innovate">
                          Submit
                        </Heading>
                      </Center>
                    )}
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </Container>
        </>
      )}

      {endCapLearnMore && <EndCapLearnMore />}
    </>
  );
}
