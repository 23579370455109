import React, { useState, useEffect, useMemo } from "react";

import { useForm, Controller } from "react-hook-form";

import { Heading, Select, Center, VStack, Box } from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";

export default function Component1(props) {
  React.useEffect(() => {
    // console.log(props);
  }, [props]);

  // react-hook-form
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const watchAllFields = watch(); // when pass nothing as argument, you are watching everything
  // Callback version of watch.  It's your responsibility to unsubscribe when done.
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // console.log(value, name, type);
      // console.log(value);
      props.onReturnComp({ role: value.Role, name: value.Name, email: value.Email, phone: value.Phone, department: value.Department });
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (data) => console.log(data);
  // console.log(errors);

  // WHEN CLICKING THROUGH WIZARD, FORCE LAYOUT
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box w={{ sm: "90%", md: "90%" }}>
        {/* <form> */}
        <Heading as="h5" size="sm" mb={"10px"}>
          Name
        </Heading>
        <Input variant="filled" value={props?.props?.name} placeholder="Your full name" size="md" {...register("Name", { required: true, maxLength: 80 })} />

        <Heading as="h5" size="sm" mt={'12px'} mb={"10px"}>
          Select what best describes you
        </Heading>
        <Select variant="filled" value={props?.props?.role} placeholder="Select your role" {...register("Role", { required: true })}>
          <option value="T-Mobile Employee">T-Mobile Employee</option>
          <option value="New partner">New partner</option>
          <option value="Current/past partner">Current/past partner</option>
          <option value="Developer w/ a bright idea">Developer w/ a bright idea</option>
          <option value="Student/academia">Student/academia</option>
        </Select>

        <Heading as="h5" size="sm" mb={"10px"} mt={"10px"}>
          Email
        </Heading>
        <Input variant="filled" value={props?.props?.email} placeholder="you@example.com" size="md" {...register("Email", { required: true, maxLength: 80 })} />

        <Heading as="h5" size="sm" mb={"10px"} mt={"10px"}>
          Mobile Phone
        </Heading>
        <Input variant="filled" value={props?.props?.phone} placeholder="000-000-0000" size="md" {...register("Phone", { required: true, maxLength: 15 })} />

        <Heading as="h5" size="sm" mb={"10px"} mt={"10px"}>
          Company
        </Heading>
        <Input
          variant="filled"
          value={props?.props?.department}
          placeholder="Example Company"
          size="md"
          {...register("Department", { required: true, maxLength: 80 })}
        />
        {/* </form> */}
      </Box>
    </>
  );
}
