import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { ChakraProvider } from "@chakra-ui/react";
import TMO5GTheme from "./styles/Theme";

import Fonts from "./fonts/Fonts";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={TMO5GTheme}>
      <Fonts />
      <App />
    </ChakraProvider>
  </React.StrictMode>
);
