import React, { useState, useEffect, useLayoutEffect } from "react";

import { Heading, useDisclosure, Select, Input, InputGroup, InputLeftElement, Spacer, Container, position } from "@chakra-ui/react";
import { Flex, Center, Text, Box } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";

import { CloseIcon } from "@chakra-ui/icons";

import { AiOutlineHome } from "react-icons/ai";

import axios from "axios";

import { formspreeUrls } from "./helpers/formspreeUrls";

import Homepage from "./components/Homepage";
import Component1 from "./components/Component_1";
import Component2 from "./components/Component_2";
import Component3 from "./components/Component_3";
// import Component4 from "./components/Component_4";
import LearnMore from "./components/LearnMore";
import EndCapSubmitYourProject from "./components/EndCapSubmitYourProject";

function App() {
  // INITIALIZE
  const [home, setHome] = useState(true);
  const [learnMore, setLearnMore] = useState(false);
  const [submitYourProject, setSubmitYourProject] = useState(false);
  const [endCapLearnMore, setEndCapLearnMore] = useState(false);
  const [endCapSubmitYourProject, setEndCapSubmitYourProject] = useState(false);

  const [formCompletionState, setFormCompletionState] = useState("nothing yet");

  const [emailIsValid, setEmailIsValid] = useState(false);

  // FORMDATA STATE VARIABLE
  const [formData, setFormData] = useState({
    role: "",
    name: "",
    email: "",
    phone: "",
    department: "",
    projectName: "",
    projectSummary: "",
    projectGoal: "",
    startDate: "",
    completionDate: "",
    howConnectToNetwork: "",
    wirelessCapabilities: "",
    supportRequirements: "",
  });

  // ███    ██  █████  ██    ██ ██  ██████   █████  ████████ ██  ██████  ███    ██
  // ████   ██ ██   ██ ██    ██ ██ ██       ██   ██    ██    ██ ██    ██ ████   ██
  // ██ ██  ██ ███████ ██    ██ ██ ██   ███ ███████    ██    ██ ██    ██ ██ ██  ██
  // ██  ██ ██ ██   ██  ██  ██  ██ ██    ██ ██   ██    ██    ██ ██    ██ ██  ██ ██
  // ██   ████ ██   ██   ████   ██  ██████  ██   ██    ██    ██  ██████  ██   ████

  const [steps, setSteps] = useState([
    { key: "firstStep", label: "My First Step", isDone: true, component: Component1 },
    { key: "secondStep", label: "My Second Step", isDone: false, component: Component2 },
    { key: "thirdStep", label: "My Third Step", isDone: false, component: Component3 },
    // { key: "fourthStep", label: "My Third Step", isDone: false, component: Component4 },
  ]);

  const [activeStep, setActiveStep] = useState(steps[0]);

  // handle NEXT Button
  const handleNext = (formCompletionState) => {
    if (steps[steps.length - 1].key === activeStep.key) {
      // SUBMIT TO FORMSPREE
      handleOnSubmit(formCompletionState);
      return;
    }

    const index = steps.findIndex((x) => x.key === activeStep.key);
    setSteps((prevStep) =>
      prevStep.map((x) => {
        if (x.key === activeStep.key) x.isDone = true;
        return x;
      })
    );
    setActiveStep(steps[index + 1]);
  };

  // handle BACK Button
  const handleBack = () => {
    const index = steps.findIndex((x) => x.key === activeStep.key);
    if (index === 0) return;

    setSteps((prevStep) =>
      prevStep.map((x) => {
        if (x.key === activeStep.key) x.isDone = false;
        return x;
      })
    );
    setActiveStep(steps[index - 1]);
  };

  // ██████  ███████ ████████ ██    ██ ██████  ███    ██ ███████
  // ██   ██ ██         ██    ██    ██ ██   ██ ████   ██ ██
  // ██████  █████      ██    ██    ██ ██████  ██ ██  ██ ███████
  // ██   ██ ██         ██    ██    ██ ██   ██ ██  ██ ██      ██
  // ██   ██ ███████    ██     ██████  ██   ██ ██   ████ ███████

  const handleReturnFromChild = (returnProps) => {
    // console.log("parent just received from child");
    // console.log(returnProps);
    // deep copy of formData
    var formDataTMP = JSON.parse(JSON.stringify(formData));
    if (returnProps.role) {
      formDataTMP.role = returnProps.role;
    } else if (returnProps.role === "") {
      formDataTMP.role = "";
    }
    if (returnProps.name) {
      formDataTMP.name = returnProps.name;
    } else if (returnProps.name === "") {
      formDataTMP.name = "";
    }
    if (returnProps.email) {
      formDataTMP.email = returnProps.email;
      if (testEmail(formDataTMP.email)) {
        setEmailIsValid(true);
      } else {
        setEmailIsValid(false);
      }
    } else if (returnProps.email === "") {
      formDataTMP.email = "";
    }
    if (returnProps.phone) {
      formDataTMP.phone = returnProps.phone;
    } else if (returnProps.phone === "") {
      formDataTMP.phone = "";
    }
    if (returnProps.department) {
      formDataTMP.department = returnProps.department;
    } else if (returnProps.department === "") {
      formDataTMP.department = "";
    }
    if (returnProps.projectName) {
      formDataTMP.projectName = returnProps.projectName;
    } else if (returnProps.projectName === "") {
      formDataTMP.projectName = "";
    }
    if (returnProps.projectSummary) {
      formDataTMP.projectSummary = returnProps.projectSummary;
    } else if (returnProps.projectSummary === "") {
      formDataTMP.projectSummary = "";
    }
    if (returnProps.projectGoal) {
      formDataTMP.projectGoal = returnProps.projectGoal;
    } else if (returnProps.projectGoal === "") {
      formDataTMP.projectGoal = "";
    }
    if (returnProps.startDate) {
      formDataTMP.startDate = returnProps.startDate;
    } else if (returnProps.startDate === "") {
      formDataTMP.startDate = "";
    }
    if (returnProps.completionDate) {
      formDataTMP.completionDate = returnProps.completionDate;
    } else if (returnProps.completionDate === "") {
      formDataTMP.completionDate = "";
    }
    if (returnProps.howConnectToNetwork) {
      formDataTMP.howConnectToNetwork = returnProps.howConnectToNetwork;
    } else if (returnProps.howConnectToNetwork === "") {
      formDataTMP.howConnectToNetwork = "";
    }
    if (returnProps.wirelessCapabilities) {
      formDataTMP.wirelessCapabilities = returnProps.wirelessCapabilities;
    } else if (returnProps.wirelessCapabilities === "") {
      formDataTMP.wirelessCapabilities = "";
    }
    if (returnProps.supportRequirements) {
      formDataTMP.supportRequirements = returnProps.supportRequirements;
    } else if (returnProps.supportRequirements === "") {
      formDataTMP.supportRequirements = "";
    }
    // setFormData
    // console.log(formDataTMP);
    setFormData(formDataTMP);
  };

  // test email validation
  function testEmail(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  // handle return from homepage
  const handleReturnFromHomepage = (returnProps) => {
    // console.log("return from homepage");
    // console.log(returnProps);
    if (returnProps === "learnMore") {
      setHome(false);
      setLearnMore(true);
    }
    if (returnProps === "submitYourProject") {
      setHome(false);
      setSubmitYourProject(true);
    }
  };

  // return from final form submission
  const handleReturnFromForm = () => {
    refreshPage();
  };

  // REFRESH / RELOAD
  const refreshPage = () => {
    // console.log("refreshing the page");
    window.location.reload();
  };

  // ██    ██  █████  ██      ██ ██████   █████  ████████ ███████
  // ██    ██ ██   ██ ██      ██ ██   ██ ██   ██    ██    ██
  // ██    ██ ███████ ██      ██ ██   ██ ███████    ██    █████
  //  ██  ██  ██   ██ ██      ██ ██   ██ ██   ██    ██    ██
  //   ████   ██   ██ ███████ ██ ██████  ██   ██    ██    ███████

  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    // conditions array
    const conditionsArray = [
      formData.role !== "",
      formData.name !== "",
      formData.email !== "",
      formData.phone !== "",
      formData.department !== "",
      formData.projectName !== "",
      formData.projectSummary !== "",
      formData.projectGoal !== "",
      formData.startDate !== "",
      formData.completionDate !== "",
      formData.howConnectToNetwork !== "",
      formData.wirelessCapabilities !== "",
      formData.supportRequirements !== "",
    ];
    // test and setFormIsValid
    if (!conditionsArray.includes(false)) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [formData]);

  // ███████  ██████  ██████  ███    ███ ███████ ██████  ██████  ███████ ███████
  // ██      ██    ██ ██   ██ ████  ████ ██      ██   ██ ██   ██ ██      ██
  // █████   ██    ██ ██████  ██ ████ ██ ███████ ██████  ██████  █████   █████
  // ██      ██    ██ ██   ██ ██  ██  ██      ██ ██      ██   ██ ██      ██
  // ██       ██████  ██   ██ ██      ██ ███████ ██      ██   ██ ███████ ███████

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      // form.reset();
      // console.log("got an OK repsonse from server");
      // time to display the end cap screen
      setLearnMore(false);
      setSubmitYourProject(false);
      setEndCapSubmitYourProject(true);
    } else {
      console.log("got an error");
    }
  };

  const handleOnSubmit = (formCompletionState) => {
    // console.log("submit to Formspree");

    var URL = "";
    if (formCompletionState === "formCompleted") {
      URL = formspreeUrls().formspreeSubmitYourProjectURL;
      setFormCompletionState("completed");
    }
    if (formCompletionState === "formPartial") {
      URL = formspreeUrls().formspreePartialSubmitYourProjectURL;
      setFormCompletionState("partial");
    }

    const form = new FormData();
    for (var key in formData) {
      form.append(key, formData[key]);
    }

    setServerState({ submitting: true });
    axios({
      method: "post",
      url: URL,
      data: form,
    })
      .then((r) => {
        handleServerResponse(true, "Thanks!", form);
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  // MISC
  const homeBtnStyle = { color: "#DDDDDD" };

  // ██████  ███████ ███████ ██████   ██████  ███    ██ ███████ ██ ██    ██ ███████
  // ██   ██ ██      ██      ██   ██ ██    ██ ████   ██ ██      ██ ██    ██ ██
  // ██████  █████   ███████ ██████  ██    ██ ██ ██  ██ ███████ ██ ██    ██ █████
  // ██   ██ ██           ██ ██      ██    ██ ██  ██ ██      ██ ██  ██  ██  ██
  // ██   ██ ███████ ███████ ██       ██████  ██   ████ ███████ ██   ████   ███████

  const [contentHeight, setContentHeight] = useState("auto");
  const [contentPadTop, setContentPadTop] = useState("auto");

  useLayoutEffect(() => {
    var width = window.innerWidth;
    var height = window.innerHeight;
    calculateContentHeight(width, height);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      // console.log(window.innerHeight, window.innerWidth);
      var width = window.innerWidth;
      var height = window.innerHeight;
      calculateContentHeight(width, height);
    });
  }, [window.innerHeight, window.innerWidth]);

  const calculateContentHeight = (width, height) => {
    var conditionsArray = [
      // ipad air portrait
      width === 820 && height === 1180,
      // ipad air landscape
      width === 1180 && height === 820,
      // ipad regular / mini portrait
      width === 768 && height === 1024,
      // ipad regular / mini landscape
      width === 1024 && height === 768,
      // ipad pro portrait
      width === 1024 && height === 1366,
      // ipad pro landscape
      width === 1366 && height === 1024,
      // desktop
      width > 1366,
    ];

    if (conditionsArray.includes(true)) {
      setContentHeight("100%");
      setContentPadTop("0");
    } else {
      setContentHeight("auto");
      setContentPadTop("5vh");
    }
  };

  // ██       █████  ██    ██  ██████  ██    ██ ████████
  // ██      ██   ██  ██  ██  ██    ██ ██    ██    ██
  // ██      ███████   ████   ██    ██ ██    ██    ██
  // ██      ██   ██    ██    ██    ██ ██    ██    ██
  // ███████ ██   ██    ██     ██████   ██████     ██

  return (
    <div className="App">
      {home && <Homepage onReturnHomepage={handleReturnFromHomepage} />}

      {!home && submitYourProject && (
        <>
          <Container p={0} position={"absolute"} maxW={"100vw"} h={"100vh"} backgroundColor={"#CF2B72"}>
            <Box position={"fixed"} top={"2"} right={"2"} style={{ cursor: "pointer" }} zIndex={"999"}>
              <AiOutlineHome style={homeBtnStyle} size={"6vmin"} onClick={() => refreshPage()} />
            </Box>

            <Flex direction={{ sm: "column", md: "row" }}>
              {/* this is the HEADER */}
              <Center
                backgroundColor={"#CF2B72"}
                w={{ sm: "100vw", md: "30vw" }}
                h={{ sm: "18vh", md: "100vh" }}
                position={"sticky"}
                top={0}
                left={0}
                zIndex={"998"}
              >
                <Flex direction={"column"} h={{ sm: "18vh", md: "100%" }} w={{ sm: "100vw", md: "30vw" }} pt={{ sm: "2%", md: "6%" }}>
                  <Center h={{ sm: "25%", md: "10%" }}>
                    <Heading fontSize={{ sm: "2.5vmin", md: "3.5vmin" }} color={"gray.100"} textAlign={"center"} variant="innovate">
                      {/* INNOVATE WITH US */}
                    </Heading>
                  </Center>

                  <Center h={{ sm: "75%", md: "90%" }} px={{ sm: "16vmin", md: "4vmin" }} mt={{ sm: "0", md: "-20" }}>
                    {activeStep.key === "firstStep" && (
                      <Heading fontSize={{ sm: "4vmin", md: "4vmin" }} color={"white"} textAlign={"center"}>
                        Ready to innovate with us? Tell us a little about yourself.
                      </Heading>
                    )}
                    {activeStep.key === "secondStep" && (
                      <Heading fontSize={{ sm: "4vmin", md: "4vmin" }} color={"white"} textAlign={"center"}>
                        What is your innovation project all about?
                      </Heading>
                    )}
                    {activeStep.key === "thirdStep" && (
                      <Heading fontSize={{ sm: "4vmin", md: "4vmin" }} color={"white"} textAlign={"center"}>
                        How can the 5G Hub support this innovation project?
                      </Heading>
                    )}
                  </Center>
                  <Center mt={{ sm: "0vmin", md: "-12vmin" }} mb={{ sm: "1vmin", md: "0vmin" }}>
                    {activeStep.key === "firstStep" && (
                      <Heading fontSize={{ sm: "2.5vmin", md: "3.5vmin" }} color={"gray.100"} textAlign={"center"} variant="innovate">
                        1/3
                      </Heading>
                    )}
                    {activeStep.key === "secondStep" && (
                      <Heading fontSize={{ sm: "2.5vmin", md: "3.5vmin" }} color={"gray.100"} textAlign={"center"} variant="innovate">
                        2/3
                      </Heading>
                    )}
                    {activeStep.key === "thirdStep" && (
                      <Heading fontSize={{ sm: "2.5vmin", md: "3.5vmin" }} color={"gray.100"} textAlign={"center"} variant="innovate">
                        3/3
                      </Heading>
                    )}
                  </Center>
                </Flex>
              </Center>
              {/* this is the CONTENT */}
              <Box backgroundColor={"white"} w={{ sm: "100%", md: "70vw" }} position={"relative"} top={"15%"} h={{ sm: "85vh", md: "100vh" }} zIndex={"1"}>
                <Flex
                  direction={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  // bg="gray.200"
                  position={"relative"}
                  pt={contentPadTop}
                  height={contentHeight}
                  pb={"2"}
                >
                  <Center w={{ sm: "90%", md: "90%" }} bg="white" pb={"6vmin"}>
                    {activeStep.key === "firstStep" && <Component1 props={formData} onReturnComp={handleReturnFromChild} />}
                    {activeStep.key === "secondStep" && <Component2 props={formData} onReturnComp={handleReturnFromChild} />}
                    {activeStep.key === "thirdStep" && <Component3 props={formData} onReturnComp={handleReturnFromChild} />}
                    {/* {activeStep.key === "fourthStep" && <Component4 props={formData} onReturnComp={handleReturnFromChild} />} */}
                  </Center>
                  {/* these are the "buttons" */}
                  <Flex alignItems={"center"} gap={{ sm: "10vmin", md: "8vmin" }} w={"80%"}>
                    {/* avtive back button */}
                    {steps[0].key !== activeStep.key && (
                      <Center w={"30vmin"} h={"12vmin"} backgroundColor={"#CF2B72"} onClick={handleBack} style={{ cursor: "pointer" }}>
                        <Heading fontSize={"3.5vmin"} color={"white"} textAlign={"center"} variant="innovate">
                          Back
                        </Heading>
                      </Center>
                    )}
                    {/* greyed out inactive back button */}
                    {steps[0].key === activeStep.key && (
                      <Center w={"30vmin"} h={"12vmin"} backgroundColor={"#CCCCCC"} style={{ cursor: "pointer" }}>
                        <Heading fontSize={"3.5vmin"} color={"white"} textAlign={"center"} variant="innovate">
                          Back
                        </Heading>
                      </Center>
                    )}

                    <Spacer />

                    {/* next only */}
                    {steps[steps.length - 1].key !== activeStep.key && (
                      <Center w={"30vmin"} h={"12vmin"} backgroundColor={"#CF2B72"} onClick={handleNext} style={{ cursor: "pointer" }}>
                        {!serverState.submitting && (
                          <Heading fontSize={"3.5vmin"} color={"white"} textAlign={"center"} variant="innovate">
                            Next
                          </Heading>
                        )}
                        {serverState.submitting && <Spinner />}
                      </Center>
                    )}

                    {/* invalid form- can't submit */}
                    {steps[steps.length - 1].key === activeStep.key && !formIsValid && (
                      <Center w={"30vmin"} h={"12vmin"} backgroundColor={"#AAAAAA"} style={{ cursor: "pointer" }}>
                        <Heading fontSize={"3.5vmin"} color={"white"} textAlign={"center"} variant="innovate">
                          Submit
                        </Heading>
                      </Center>
                    )}

                    {/* valid form- can submit */}
                    {steps[steps.length - 1].key === activeStep.key && formIsValid && (
                      <Center w={"30vmin"} h={"12vmin"} backgroundColor={"#CF2B72"} onClick={() => handleNext("formCompleted")} style={{ cursor: "pointer" }}>
                        {!serverState.submitting && (
                          <Heading fontSize={"3.5vmin"} color={"white"} textAlign={"center"} variant="innovate">
                            Submit
                          </Heading>
                        )}
                        {serverState.submitting && <Spinner />}
                      </Center>
                    )}
                  </Flex>
                  {submitYourProject && !emailIsValid && (
                    <Heading mt={"3.5vmin"} fontSize={"3vmin"} color={"#DDDDDD"} textAlign={"center"} variant="innovate">
                      Enter Email to Finish Later
                    </Heading>
                  )}
                  {submitYourProject && emailIsValid && (
                    <Heading
                      mt={"3.5vmin"}
                      fontSize={"3vmin"}
                      color={"#CF2B72"}
                      textAlign={"center"}
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      variant="innovate"
                      onClick={() => handleOnSubmit("formPartial")}
                    >
                      Finish Later
                    </Heading>
                  )}
                </Flex>
              </Box>
            </Flex>
          </Container>
        </>
      )}

      {!home && learnMore && <LearnMore onReturnFromForm={handleReturnFromForm} />}

      {!home && endCapSubmitYourProject && <EndCapSubmitYourProject props={formCompletionState} onReturnFromForm={handleReturnFromForm} />}
    </div>
  );
}

export default App;
