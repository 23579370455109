import React, { useState, useEffect, useMemo } from "react";

import { Image, Container, Heading, Select, Center, VStack, Box, Flex, Button, Spacer } from "@chakra-ui/react";

export default function EndCapLearnMore(props) {
  // REFRESH / RELOAD
  const refreshPage = () => {
    // console.log("refreshing the page");
    window.location.reload();
  };

  return (
    <>
      <Container centerContent={true} maxW={"100vw"} h={"100vh"} backgroundColor={"#CF2B72"} px={{ sm: "5vmin", md: "15vmin" }}>
        <Flex direction={"column"} alignItems={"center"} h={"100%"}>
          <Heading mt={"8vmin"} fontSize={{ sm: "2.5vmin", md: "3.5vmin" }} color={"gray.100"} textAlign={"center"} variant="innovate">
            INNOVATE WITH US
          </Heading>
          <Spacer />

          <Center>
            <Flex direction={"column"}>
              <Heading fontSize={{ sm: "5vmin", md: "5vmin" }} color={"white"} textAlign={"center"}>
                Thanks for submitting your details!
              </Heading>
              <Heading mt={"10px"} fontSize={{ sm: "5vmin", md: "5vmin" }} color={"white"} textAlign={"center"}>
                The team will review and get back to you within 5 business days.
              </Heading>
            </Flex>
          </Center>

          <Spacer />
          <Center w={"40vmin"} h={"20vmin"} backgroundColor={"white"} onClick={() => refreshPage()} style={{ cursor: "pointer" }}>
            <Heading fontSize={"4vmin"} color={"#CF2B72"} textAlign={"center"} variant="innovate">
              Main Menu
            </Heading>
          </Center>
          <Spacer />
        </Flex>
      </Container>
    </>
  );
}
