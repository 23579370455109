import { extendTheme } from "@chakra-ui/react";

// breakpoints expained:
// sm, from zero pixel width -> md, 820 pixel width
// md, from 820 pixel width -> md, 960 pixel width
// lg, from 960 pixel width upwards

const TMO5GTheme = extendTheme({
  breakpoints: {
    sm: "0px",
    md: "1025px",
    lg: "960px",
  },
  components: {
    Heading: {
      variants: {
        innovate: {
          fontFamily: `'TeleNeo-Thin', sans-serif`,
        },
      },
    },
    Button: {
      variants: {
        btnMagenta: {
          border: "4px solid white",
          bg: "#CF2B72",
          color: "white",
          label: {
            pointerEvents: "none",
          },
        },
        btnWhite: {
          bg: "white",
          color: "#CF2B72",
        },
      },
      sizes: {
        lg: {
          borderRadius: "none",
        },
      },
    },
  },
  fonts: {
    heading: `'TeleNeo-Bold', sans-serif`,
    body: `'TeleNeo-Thin', sans-serif`,
  },
});

export default TMO5GTheme;
