import React, { useState, useEffect, useMemo } from "react";

import { Image, Container, Heading, Select, Center, VStack, Box, Flex, Button, Spacer } from "@chakra-ui/react";

export default function Homepage(props) {
  const handleLearnMoreBtn = () => {
    // console.log("handle Learn More Button");
    props.onReturnHomepage("learnMore");
  };

  const handleSubmitYourProjectBtn = () => {
    // console.log("handle Submit Your Project Button");
    props.onReturnHomepage("submitYourProject");
  };

  return (
    <>
      <Container centerContent={true} position={"absolute"} maxW={"100vw"} h={"100vh"} backgroundColor={"#CF2B72"}>
        <Flex direction={"column"} w={"100%"} h={"100%"} >
          <Spacer />
          <Heading fontSize={{ sm: "20vmin", md: "20vmin" }} color={"white"} textAlign={"center"}>
            5G Hub
          </Heading>

          <Heading fontSize={{ sm: "6vmin", md: "8vmin" }} color={"white"} textAlign={"center"} variant="innovate">
            INNOVATE WITH US
          </Heading>

          <Spacer />
          <Center>
            <Flex gap={"20px"} direction={{ sm: "column", md: "row" }}>
              <Center
                w={"40vmin"}
                h={"20vmin"}
                backgroundColor={"#CF2B72"}
                border={"4px solid white"}
                onClick={() => handleLearnMoreBtn()}
                style={{ cursor: "pointer" }}
              >
                <Heading fontSize={"4vmin"} color={"white"} textAlign={"center"} variant="innovate">
                  Contact Me
                </Heading>
              </Center>

              <Center w={"40vmin"} h={"20vmin"} backgroundColor={"white"} onClick={() => handleSubmitYourProjectBtn()} style={{ cursor: "pointer" }}>
                <Heading fontSize={"4vmin"} color={"#CF2B72"} textAlign={"center"} variant="innovate">
                  Submit a Project
                </Heading>
              </Center>
            </Flex>
          </Center>
          <Spacer />
        </Flex>
      </Container>
    </>
  );
}
